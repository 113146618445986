import React from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import ConfigForm from './components/ConfigForm';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/config/:nodeType" component={ConfigForm} />
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;

