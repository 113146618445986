import React from 'react';
import { connect } from 'react-redux';
import { saveConfig } from '../features/config/configSlice';

const downloadConfig = (configData) => {
  const fileContent = JSON.stringify(configData);
  const blob = new Blob([fileContent], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'config.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ConfigForm = ({ config, onSave }) => {
  const [formData, setFormData] = React.useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const configData = Object.fromEntries(formData.entries());
    onSave(configData);
    downloadConfig(configData);
  };

  return (
    <form onSubmit={handleSubmit}>
      {Object.entries(schema[nodeType]).map(([key, value]) => (
        <label key={key}>
          {key}:
          <input
            type="text"
            name={key}
            value={formData[key] || ''}
            onChange={handleChange}
          />
        </label>
      ))}
      <button type="submit">Save Configuration</button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  config: state.config,
});

const mapDispatchToProps = {
  onSave: saveConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigForm);
